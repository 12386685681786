<template>
    <div id="payment" class="form">
        <h1>PAYMENT INFORMATION</h1>
        <h2 v-if="account_name">Account: {{ account_name }}</h2>

        <b-form @submit="onSubmit" @reset="onReset" v-if="show" class="mt-4">
            <b-overlay :show="loading"
                       bg-color="#ddd">

                <b-row>
                    <b-col cols="12" sm="6">
                        <b-form-group
                            label="Card Number"
                            label-for="input-card"
                            label-class="font-weight-bold">
                            <b-form-input v-model="card"
                                          id="input-card"
                                          :disabled="disabled"/>
                            <div v-if="v$.card.$dirty && v$.card.$errors.length" class="error-msg">
                                Please enter your credit card number
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" xs="8" sm="4">
                        <b-form-group
                            label="Expiration"
                            label-for="input-month"
                            label-class="font-weight-bold"
                            class="card-expiration">
                            <b-container fluid class="p-0">
                                <b-row>
                                    <b-col class="pr-0">
                                        <b-form-select v-model="month"
                                                       id="input-month"
                                                       :options="monthOptions"
                                                       :disabled="disabled"
                                                       class="card-expiration__month"/>
                                    </b-col>
                                    <b-col class="pl-2">
                                        <b-form-select v-model="year"
                                                       :options="yearOptions"
                                                       :disabled="disabled"
                                                       class="card-expiration__year"/>
                                    </b-col>
                                </b-row>
                            </b-container>
                            <div
                                v-if="v$.month.$dirty && v$.year.$dirty && (v$.month.$errors.length || v$.year.$errors.length)"
                                class="error-msg">
                                Please enter the expiration date
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" xs="4" sm="2">
                        <b-form-group
                            label="CVV"
                            label-for="input-cvv2"
                            label-class="font-weight-bold"
                            class="card-cvv"
                        >
                            <b-form-input v-model="cvv2"
                                          id="input-cvv2"
                                          :disabled="disabled"/>
                            <div v-if="v$.cvv2.$dirty && v$.cvv2.$errors.length" class="error-msg">
                                Please enter the card verification value
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-form-group
                            label="Name on Card"
                            label-for="input-name"
                            label-class="font-weight-bold">
                            <b-form-input v-model="name"
                                          id="input-name"
                                          :disabled="disabled"/>
                            <div v-if="v$.name.$dirty && v$.name.$errors.length" class="error-msg">
                                Please enter name on your credit card
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-form-group
                            label="Address"
                            label-for="input-address"
                            label-class="font-weight-bold">
                            <b-form-input v-model="address"
                                          id="input-address"
                                          :disabled="disabled"/>
                            <div v-if="v$.address.$dirty && v$.address.$errors.length" class="error-msg">
                                Please enter your credit card address
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" md="6">
                        <b-form-group
                            label="City"
                            label-for="input-city"
                            label-class="font-weight-bold">
                            <b-form-input v-model="city"
                                          id="input-city"
                                          :disabled="disabled"/>
                            <div v-if="v$.city.$dirty && v$.city.$errors.length" class="error-msg">
                                Please enter your city
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" md="3">
                        <b-form-group
                            label="State"
                            label-for="input-state"
                            label-class="font-weight-bold"
                            class="card-state"
                        >
                            <b-form-select v-model="state"
                                           id="input-state"
                                           :options="stateOptions"
                                           :disabled="disabled"/>
                            <div v-if="v$.state.$dirty && v$.state.$errors.length" class="error-msg">
                                Please enter your state
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" md="3">
                        <b-form-group
                            label="Zip"
                            label-for="input-zip"
                            label-class="font-weight-bold"
                            class="card-zip"
                        >
                            <b-form-input v-model="zip"
                                          id="input-zip"
                                          :disabled="disabled"/>
                            <div v-if="v$.zip.$dirty && v$.zip.$errors.length" class="error-msg">
                                Please enter your zip code
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <template v-if="!has_managed_wifi">
                            <b-form-group label="WiFi Access:"
                                          label-class="font-weight-bold">
                                <div class="mx-4">
                                    Your apartment building has managed WiFi.
                                    Access credentials to this service will be sent after account activation.
                                </div>
                            </b-form-group>
                        </template>
                        <template v-else>
                            <b-form-group label="WiFi Router:"
                                          label-for="input-router"
                                          label-class="font-weight-bold">
                                <b-checkbox v-model="router"
                                            id="input-router">
                                    You will need your own WiFi router or you can choose to lease a router from
                                    Fiberspark.
                                    Check this box to lease an advanced WiFi router from Fiberspark at
                                    <strong>$8/month</strong>.
                                </b-checkbox>
                            </b-form-group>
                        </template>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <b-form-group label="Fiberspark Service Agreement:"
                                      label-for="input-accept_fee"
                                      label-class="font-weight-bold">
                            <b-checkbox v-model="accept_fee"
                                        id="input-accept_fee"
                                        :disabled="disabled">
                                I authorize Fiberspark to collect the total amount of the service fee, from my
                                designated form of
                                payment on a recurring monthly basis. I understand that I will receive an electronic
                                invoice at the
                                beginning of the month and be charged 10 days later.
                            </b-checkbox>
                            <div v-if="v$.accept_fee.$dirty&& v$.accept_fee.$errors.length" class="error-msg">
                                Please authorize the payments.
                                <br/>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="form-submit">
                    <b-col sm="12" class="text-center">
                        <b-button v-if="!submitted"
                                  type="submit"
                                  class="px-5"
                                  variant="primary"
                                  :disabled="disabled">
                            Submit
                        </b-button>
                        <b-button v-else
                                  class="px-5"
                                  variant="secondary"
                                  :disabled="true">
                            Submitted
                        </b-button>
                        <br/>
                        <br/>
                    </b-col>
                </b-row>

            </b-overlay>
        </b-form>
    </div>
</template>

<script>

import Api from '@/providers/Api'
import {useVuelidate} from '@vuelidate/core'
import * as _ from 'lodash'
import {required, minLength, maxLength} from '@vuelidate/validators'
import Utils from '@/providers/Utils'
import {DateTime} from 'luxon'

function valueIsTrue(value) {
    return value
}

export default {
    name: 'PaymentView',
    components: {},
    props: {
        id: String,
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            account_id: null,
            account: null,
            card: '',
            month: '',
            year: '',
            cvv2: '',
            name: '',
            address: '',
            city: '',
            state: 'NY',
            zip: '',
            show: true,
            submitted: false,
            loading: false,
            accept_fee: false,
            router: false,
            has_managed_wifi: null,
            //watching: true,
            monthOptions: [
                {value: '', text: 'month'},
                {value: 1, text: '01 - January'},
                {value: 2, text: '02 - February'},
                {value: 3, text: '03 - March'},
                {value: 4, text: '04 - April'},
                {value: 5, text: '05 - May'},
                {value: 6, text: '06 - June'},
                {value: 7, text: '07 - July'},
                {value: 8, text: '08 - August'},
                {value: 9, text: '09 - September'},
                {value: 10, text: '10 - October'},
                {value: 11, text: '11 - November'},
                {value: 12, text: '12 - December'},
            ],
            yearOptions: _.concat(
                [{value: '', text: 'year'}],
                _.map(_.range(DateTime.now().get('year'), DateTime.now().get('year') + 10), year => {
                    return {
                        value: year,
                        text: year.toString()
                    }
                })
            ),
            states: {
                AK: "Alaska",
                AL: "Alabama",
                AR: "Arkansas",
                AZ: "Arizona",
                CA: "California",
                CO: "Colorado",
                CN: "Connecticut",
                DC: "District of Columbia",
                DE: "Delaware",
                FL: "Florida",
                GA: "Georgia",
                HI: "Hawaii",
                IA: "Iowa",
                ID: "Idaho",
                IL: "Illinois",
                IN: "Indiana",
                KS: "Kansas",
                KY: "Kentucky",
                LA: "Louisiana",
                MA: "Massachusetts",
                MD: "Maryland",
                ME: "Maine",
                MI: "Michigan",
                MN: "Minnesota",
                MO: "Missouri",
                MS: "Mississippi",
                MT: "Montana",
                NC: "North Carolina",
                ND: "North Dakota",
                NE: "Nebraska",
                NH: "New Hampshire",
                NJ: "New Jersey",
                NM: "New Mexico",
                NV: "Nevada",
                NY: "New York",
                OH: "Ohio",
                OK: "Oklahoma",
                OR: "Oregon",
                PA: "Pennsylvania",
                RI: "Rhode Island",
                SC: "South Carolina",
                SD: "South Dakota",
                TN: "Tennessee",
                TX: "Texas",
                UT: "Utah",
                VA: "Virginia",
                VT: "Vermont",
                WA: "Washington",
                WV: "West Virginia",
                WI: "Wisconsin",
                WY: "Wyoming",
                AS: "American Samoa",
                FM: "Federated States of Micronesia",
                GU: "Guam",
                MH: "Marshall Islands",
                MP: "Northern Mariana Islands",
                PW: "Palau",
                PR: "Puerto Rico",
                VI: "Virgin Islands",
                AA: "Armed Forces Americas excluding Canada",
                AE: "Armed Forces Europe, Middle East, Africa, Canada",
                AP: "Armed Forces Pacific"
            },
        }
    },
    validations() {
        return {
            card: {required, minLength: minLength(15), maxLength: maxLength(16), $lazy: true},
            month: {required, $lazy: true},
            year: {required, $lazy: true},
            cvv2: {required, minLength: minLength(3), $lazy: true},
            name: {required, $lazy: true},
            address: {required, $lazy: true},
            city: {required, $lazy: true},
            state: {required, $lazy: true},
            zip: {required, minLength: minLength(5), $lazy: true},
            accept_fee: {valueIsTrue, $lazy: true},
            router: {}
        }
    },
    computed: {
        account_name: function () {
            return this.account?.name
        },
        stateOptions: function () {
            return _.concat(
                [{value: '', text: ''}],
                _.map(Object.keys(this.states), abbr => {
                    return {
                        value: abbr,
                        text: this.states[abbr]
                    }
                })
            )
        },
        disabled: function () {
            return !this.account
        }
    },
    methods: {
        async onSubmit(event) {
            event.preventDefault()
            // touch everything
            this.v$.card.$dirty = true
            this.v$.month.$dirty = true
            this.v$.year.$dirty = true
            this.v$.cvv2.$dirty = true
            this.v$.address.$dirty = true
            this.v$.city.$dirty = true
            this.v$.state.$dirty = true
            this.v$.zip.$dirty = true
            // vuelidate
            const isFormCorrect = await this.v$.$validate()
            if (isFormCorrect) {
                // create note
                const todayHuge = DateTime.now().toLocaleString(DateTime.DATE_HUGE)
                const note = `Payment submitted online ${todayHuge}.`
                // actually submit form
                this.submitted = true
                Api.payment(this.account_id, this.card, this.month, this.year, this.cvv2, this.name, this.address, this.city, this.state, this.zip, note)
                    .then(response => {
                        if (response) {
                            if (response.error)
                                throw new Error(response.error)
                            else {
                                // retrieve status
                                const status = this.$store.state.status
                                if (status !== 'INUSE') {
                                    // set to active or schedule a future activation date
                                    const start_date = this.$store.state.start_date
                                    // problem: today should be UTC time
                                    const todaySQL = DateTime.now().toSQLDate()
                                    if (start_date && (start_date > todaySQL)) {
                                        // schedule activation
                                        const description = 'Activation scheduled for ' + start_date
                                        return Api.schedule(this.account_id, start_date, description)
                                    } else
                                        // activate immediately
                                        return Api.activate(this.account_id)
                                } else
                                    return true
                            }
                        }
                    })
                    .then(response => {
                        if (response) {
                            if (response.error)
                                throw new Error(response.error)
                            else {
                                // retrieve status and start date
                                const status = this.$store.state.status
                                const start_date = this.$store.state.start_date
                                const display_date = start_date ? DateTime.fromSQL(start_date).toLocaleString(DateTime.DATE_HUGE) : ''
                                // craft notification message
                                const message = 'Payment information has been entered:\n\n' +
                                    'Name: ' + this.account_name + '\n' +
                                    'Start date: ' + display_date + '\n' +
                                    'Status: ' + status + '\n' +
                                    '\n' +
                                    'Sonar URL: ' + 'https://fiberspark.sonar.software/app#/accounts/show/' + this.account_id
                                // send notification message; no exceptions
                                return Api.sendmail('Payment method added', message, 'SUPPORT')
                            }
                        }
                    })
                    .then(() => {
                        // forward to success page
                        this.$router.push({name: 'done'})
                    })
                    .catch(error => {
                        const message = (error && error.message) ? error.message : (error ? error : 'An error occurred')
                        this.$toasted.error(message)
                    })
                    .finally(() => {
                        this.submitted = false
                    })
            } else
                // display toasted error
                this.$toasted.error('Please check the form errors and resubmit your form')
        },
        onReset(event) {
            event.preventDefault()
            // reset our form values
            this.card = ''
            this.month = ''
            this.year = ''
            this.cvv2 = ''
            this.name = ''
            this.address = ''
            this.city = ''
            this.state = 'NY'
            this.zip = ''
            // trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        },
    },
    mounted() {
        //  DEBUG
        //  http://localhost:8080/#/payment/02010005 for account_id=1236
        if (this.id)
            this.account_id = Utils.decrypt(this.id.toString())
        if (!this.account_id)
            this.account_id = this.$store?.state?.account_id
        if (this.account_id) {
            this.loading = true
            Api.account(this.account_id)
                .then(response => {
                    if (response && response.data && response.data.accounts && response.data.accounts.entities && Array.isArray(response.data.accounts.entities) && response.data.accounts.entities.length) {
                        this.account = response.data.accounts.entities[0]
                        // populate fields
                        if (this.account) {
                            if (this.account.name)
                                this.name = this.account.name
                            if (this.account.addresses?.entities?.length) {
                                // order addresses by serviceable setting
                                let addresses = _.cloneDeep(this.account.addresses?.entities)
                                addresses = _.orderBy(addresses, ['serviceable'], ['desc']);
                                // retrieve first address
                                const address = addresses[0]
                                //console.log('Payment:mounted  address=', address)
                                if (address.line1)
                                    this.address = address.line1 + (address.line2 ? ', ' + address.line2 : '')
                                if (address.city)
                                    this.city = address.city
                                if (address.subdivision && (address.subdivision.substring(0, 3) === 'US_'))
                                    this.state = address.subdivision.substring(3)
                                if (address.zip)
                                    this.zip = address.zip
                                // check for managed wifi
                                if (address.custom_field_data?.entities) {
                                    const custom = _.find(address.custom_field_data.entities, custom => (custom.custom_field_id === '1'))
                                    if (custom)
                                        this.has_managed_wifi = (custom.value === 1) || (custom.value === '1')
                                }
                            }
                        } else
                            this.$toasted.error('Unable to load account')
                    } else if (response && response.errorMessage)
                        this.$toasted.error('Error: ' + response.errorMessage)
                    else
                        this.$toasted.error('An unexpected error occurred while accessing server')
                })
                .catch(error => {
                    const message = (error && error.message) ? error.message : (error ? error : 'An error occurred')
                    this.$toasted.error(message)
                })
                .finally(() => {
                    this.loading = false
                })
        } else {
            this.$toasted.error('Unable to retrieve account identifier')
            this.$router.push({name: 'signup'})
        }
    }
}
</script>

<style lang="scss">

$red: #C3292F;
$blue: #007bff;

#payment {
    max-width: 800px;
    margin: 0 auto;
}

//.card-expiration {
//  @media (max-width: 767px) {
//    select {
//      &:first-child {
//        margin-bottom: 10px;
//      }
//    }
//    max-width: 180px;
//  }
//  @media (min-width: 768px) {
//    select {
//      width: 38%;
//
//      &:first-child {
//        width: 60%;
//        margin-right: 2%;
//      }
//    }
//  }
//}

.card-state, .card-zip, .card-cvv, .card-expiration__month, .card-expiration__year {
    max-width: 180px;
}

</style>
