import Vue from 'vue'
import VueRouter from 'vue-router'
import Signup from './views/Signup.vue'
import Payment from './views/Payment.vue'
import Done from './views/Done.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'signup',
    component: Signup
  },
  {
    path: '/payment/:id',
    name: 'payment',
    component: Payment,
    props: (route) => ({
      id: route.params.id,
    }),
  },
  {
    path: '/done',
    name: 'done',
    component: Done
  }
]

const router = new VueRouter({
  routes
})

export default router
