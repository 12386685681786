import Vue from 'vue'
import VueResource from 'vue-resource'
import Utils from '@/providers/Utils'

Vue.use(VueResource)

// constants

const ADDRESS_RECORDS= 5

export default {

  // variables

  proxyUrl: process.env.VUE_APP_PROXY_URL ? `${process.env.VUE_APP_PROXY_URL}/` : null,

  // helper function

  async invokeApi(params) {
    if (!this.proxyUrl)
      return {error: 'API not found'}
    const config = {
      method: 'GET',
      responseType: 'json',
      params: params
    }
    return Vue.http.get(this.proxyUrl, config)
      .then(response => {
        console.log('Api:invokeApi  response.data=', response.data)
        if (response.data?.errorMessage)
          return {body: response, error: response.data?.errorMessage}
        return response.data
      })
      .catch(response => {
        console.error('Api.invokeApi  error=', response)
        return {body: response.body, error: response.body.error}
      })
  },

  // Sonar API calls

  async addressLookup(term, page = undefined) {
    const params = {
      action: 'address_search',
      term: term,
      page: page,
      records: ADDRESS_RECORDS
    }
    return this.invokeApi(params)
  },

  async addressAccount(account) {
    const params = {
      action: 'address_by_account',
      account: Utils.encrypt(account)
    }
    return this.invokeApi(params)
  },

  async accountAddress(address) {
    const params = {
      action: 'account_by_address',
      address: address
    }
    return this.invokeApi(params)
  },

  async account(account) {
    const params = {
      action: 'account',
      account: Utils.encrypt(account)
    }
    return this.invokeApi(params)
  },

  async signup(name, email, phone, address, address_id, note) {
    const params = {
      action: 'signup',
      name: name,
      email: email,
      phone: phone,
      address: address,
      address_id: address_id,
      note: note
    }
    return this.invokeApi(params)
  },

  async service(account, service) {
    const params = {
      action: 'service',
      account: Utils.encrypt(account),
      service: service
    }
    return this.invokeApi(params)
  },

  async statuses() {
    const params = {
      action: 'statuses'
    }
    return this.invokeApi(params)
  },

  async network_sites() {
    const params = {
      action: 'network_sites'
    }
    return this.invokeApi(params)
  },

  async payment(account, card, month, year, cvv2, name, address, city, state, zip, note) {
    const params = {
      action: 'payment',
      account: Utils.encrypt(account),
      card: Utils.encrypt(card),
      month: month,
      year: year,
      cvv2: Utils.encrypt(cvv2),
      name: name,
      address: address,
      city: city,
      state: state,
      zip: zip,
      note: note
    }
    return this.invokeApi(params)
  },

  async activate(account) {
    const params = {
      action: 'activate',
      account: Utils.encrypt(account)
    }
    return this.invokeApi(params)
  },

  async deactivate(account) {
    const params = {
      action: 'deactivate',
      account: Utils.encrypt(account)
    }
    return this.invokeApi(params)
  },

  async schedule(account, datetime, description) {
    //console.log('Api:schedule  account=', account, '  datetime=', datetime, '  description=', description)
    const params = {
      action: 'schedule',
      account: Utils.encrypt(account),
      datetime: datetime,
      description: description
    }
    return this.invokeApi(params)
  },

  // AWS-SES API

  async sendmail(subject, message, module = 'SUPPORT') {
    // setup parameters
    const params = {
      action: 'sendmail',
      subject: subject,
      message: message,
      module: module
    }
    //   // check environment
    //   // let env = process.env.NODE_ENV
    //   // if (env === 'development')
    //   //   env = 'dev'
    //   // let proxyUrl = this.proxyUrl + env
    //   // console.log('Api:sendEmail  process.env=', process.env, '  this.proxyUrl=', this.proxyUrl, '  proxyUrl=', proxyUrl)
    //   // post message
    return Vue.http.post(this.proxyUrl, params)
      .then(response => {
        //console.log('Api:sendEmail  response.data=', response.data)
        if (response.data?.errorMessage)
          return {body: response, error: response.data?.errorMessage}
        return response.data
      })
      .catch(response => {
        console.error('Api:sendEmail  error=', response)
        return {body: response.body, error: response.body.error}
      })
  },
}
