<template>
  <div class="sample-component">
  </div>
</template>

<script>

export default {
  name: 'SampleComponent',
  components: {},
  data() {
    return {
    }
  },
  methods: {},
}
</script>
