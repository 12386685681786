<template>
  <div id="done" class="form">
    <h1>Account Created</h1>
    <h3>Congratulations! Your Fiberspark account has been created!</h3>
    <b-overlay :show="loading"
               bg-color="#ddd"
               class="success-message">
      <p v-html="content"/>
      <p v-html="content2"/>
    </b-overlay>
  </div>
</template>

<script>
import Api from '@/providers/Api'
import Utils from '@/providers/Utils'
import {DateTime} from 'luxon'

export default {
  name: 'PaymentView',
  components: {},
  props: {
    id: String,
  },
  data() {
    return {
      account_id: null,
      account: null,
      loading: false,
      content: null,
      content2: null,
    }
  },
  watch: {
    account(newVal, oldVal) {
      // changes only
      if (newVal !== oldVal) {
        // clear content
        this.content = null
        this.content2 = null
        // craft new content
        if (this.account) {
          // get status
          const status = this.$store.state.status   //  ACTIVATE,SCHEDULE,NOSERVICE
          // retrieve date
          const start_date = this.$store.state.start_date
          const display_date = start_date ? 'on <strong>' + DateTime.fromSQL(start_date).toLocaleString(DateTime.DATE_HUGE) + '</strong>' : ' when available'
          // retrieve address
          const addresses = this.account?.addresses?.entities
          const address = (addresses && addresses.length) ? ' at <strong>' + Utils.formatAddress(addresses[0]) + '</strong>' : ''
          // craft message
          if (status !== 'NOSERVICE') {
            this.content = 'Your Fiberspark service will begin ' + display_date + address + '.'
            this.content2 = 'We will get back to you soon!'
          } else
            this.content = 'Someone from our team will reach out to you soon to discuss Internet availability' + address + '.'
        } else
          this.content = '(unable to retrieve account information)'
      }
    },
  },
  methods: {},
  mounted() {
    if (this.id)
      this.account_id = Utils.decrypt(this.id.toString())
    if (!this.account_id)
      this.account_id = this.$store?.state?.account_id
    if (this.account_id) {
      this.loading = true
      Api.account(this.account_id)
        .then(response => {
          if (response && response.data && response.data.accounts && response.data.accounts.entities && Array.isArray(response.data.accounts.entities) && response.data.accounts.entities.length)
            this.account = response.data.accounts.entities[0]
          else if (response && response.errorMessage)
            this.$toasted.error('Error: ' + response.errorMessage)
          else
            this.$toasted.error('An unexpected error occurred while loading your account')
        })
        .catch(error => {
          const message = (error && error.message) ? error.message : (error ? error : 'An error occurred')
          this.$toasted.error(message)
        })
        .finally(() => {
          this.loading = false
        })
    } else
      this.$toasted.error('Unable to retrieve account identifier')
  }
}
</script>

<style lang="scss">
$red: #C3292F;
$blue: #007bff;
#done {
  padding: 3em 0 2em;
  max-width: 600px;
  margin: 0 auto;
}

.success-message {
  margin-top: 2em;
  border: solid 4px $red;
  padding: 2em;
  text-align: center;
  background-color: #eee;
  font-size: 18px;
  box-shadow: 0 0 0 1rem rgba(195, 41, 47, .3);
}

</style>
