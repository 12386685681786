import Vue from 'vue'
import * as _ from 'lodash'

// constants

const SALT= '14850'

export default {

  // globally register all components in the 'src/components' directory (using kebab-case)
  registerComponents() {
    const requireComponent = require.context(
      // the relative path of the components folder
      '@/components',
      // whether to look in subfolders
      true,
      // the regular expression used to match base component filenames
      /[A-Z]\w+\.(vue|js)$/
    )

    requireComponent.keys().forEach(fileName => {
      // get component config
      const componentConfig = requireComponent(fileName)
      // get PascalCase name of component
      const componentName = _.upperFirst(
        _.camelCase(
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, ''),
        )
      )
      const kebabName = componentName.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
      // register component globally
      Vue.component(
        kebabName,
        // look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
      )
    })
  },

  handleError(e, message = undefined) {
    Vue.toasted.error(message ? message : e.body.message)
    //console.log('Utils:handleError  sentry capturing', process.env.VUE_APP_SENTRY_DSN)
    // Sentry.captureException(e)
  },

  encrypt(text, salt) {
    if (!salt)
      salt = SALT
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0))
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2)
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code)

    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("")
  },

  decrypt(encoded, salt) {
    if (!salt)
      salt = SALT
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0))
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code)
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("")
  },

  formatAddress(address) {
    let result = ''
    if (address) {
      result = address.line1
      if (address.line2)
        result += ', ' + address.line2
      if (address.city)
        result += ', ' + address.city
      if (address.subdivision && (address.subdivision.length === 5))
        result += ', ' + address.subdivision.substring(3)
      if (address.zip)
        result += ' ' + address.zip
    }
    return result.trim()
  }
}
