import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'

// bootstrap including SCSS

import { BootstrapVue, LayoutPlugin, IconsPlugin } from 'bootstrap-vue'
import './app.scss'

// toasted (https://github.com/shakee93/vue-toasted#custom-toast-registration)

import VueToasted from "vue-toasted";

Vue.use(VueToasted, {
  iconPack: 'fontawesome',
  duration: 5000,
  dismissible: true,
  pauseOnHover: true
});
let toastedOptions = {
  duration: 10000,
  action: {
    text: '   X',
    onClick: (event, toastObject) => toastObject.goAway(0),
  },
};
Vue.toasted.register('info', payload => payload || "", {
  ...toastedOptions,
  className: ['info'],
  type: 'info'
});
Vue.toasted.register('success', payload => payload || "Success!", {
  ...toastedOptions,
  className: ['success'],
  type: 'success'
});
//Vue.toasted.register('error', payload => payload ? (payload.error ? payload.error : JSON.stringify(payload) + 'X') : "An error occurred", {
Vue.toasted.register(
  'error',
  payload => {
    //console.log("Vue.toasted.error  payload=" + JSON.stringify(payload));
    if (Object.keys(payload).length === 0)
      return "An error occurred";
    return payload.error || payload || "An error occurred";
  },
  {
    ...toastedOptions,
    className: ['error'],
    type: 'error'});

// vue-tel-input

import VueTelInput from  'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
Vue.use(VueTelInput);

// plugins

Vue.config.productionTip = false
// make BootstrapVue available throughout your project, including plugins
Vue.use(BootstrapVue)
Vue.use(LayoutPlugin)
Vue.use(IconsPlugin)

// http

// Vue.http.interceptors.push(function(request) {
//   console.log('main:http.interceptors  request=', request)
//   // modify method
//   // request.method = 'POST';
//   //
//   // // modify headers
//   // request.headers.set('X-CSRF-TOKEN', 'TOKEN');
//   // request.headers.set('Authorization', 'Bearer TOKEN');
//
// });

// main Vue object

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
