<template>
    <div id="signup" class="form">
        <h1>CUSTOMER SIGNUP</h1>
        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-container>
                <b-row>
                    <b-col sm="12">
                        <b-form-group label="Full Name"
                                      label-for="input-name"
                                      label-class="font-weight-bold">
                            <b-form-input v-model="name"
                                          id="input-name"
                                          type="text"
                                          @focus="hideAddresses"/>
                            <div v-if="v$.name.$dirty && v$.name.$errors.length" class="error-msg">
                                Please enter your first and last names
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6">
                        <b-form-group label="Email"
                                      label-for="input-email"
                                      label-class="font-weight-bold">
                            <b-form-input v-model="email"
                                          id="input-email"
                                          type="email"
                                          @focus="hideAddresses"/>
                            <div v-if="v$.email.$dirty && v$.email.$errors.length" class="error-msg">
                                Please enter your email
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Phone"
                                      label-for="input-phone"
                                      label-class="font-weight-bold">
                            <vue-tel-input v-model="phone"
                                           id="input-phone"
                                           name="phone"
                                           defaultCountry="US"
                                           :autoDefaultCountry="false"
                                           :inputOptions="{id:'input-phone',autocomplete:'off',styleClasses:'tel'}"
                                           @input="hideAddresses"
                                           @open="hideAddresses"
                                           @validate="validatePhone"/>
                            <div v-if="v$.phone.$dirty && v$.phone.$errors.length" class="error-msg">
                                Please enter your phone number
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Service Address (including city, state, and zip)"
                                      label-for="input-address"
                                      label-class="font-weight-bold">
                            <b-input-group>
                                <b-form-input v-model="address"
                                              id="input-address"
                                              autocomplete="off"
                                              debounce="250"
                                              placeholder="123 Any Street, City, NY 10000"
                                              @focus="focusAddresses"/>
                                <!--                <template #append>-->
                                <!--                  <b-input-group-text>-->
                                <!--                    <b-icon icon="question" @click="addressDetails"/>-->
                                <!--                  </b-input-group-text>-->
                                <!--                </template>-->
                            </b-input-group>

                            <div v-if="showAddresses" class="items">
                                <b-overlay :show="loading">
                                    <div>
                                        <ul class="item" v-for="item in items" :key="item.id">
                                            <li><a @click="addressSelect(item.id, item.name, item.serviceability_status, item.network_site)">{{ item.name }}</a></li>
                                        </ul>
                                        <b-button v-if="showNext"
                                                  :disabled="loading"
                                                  @click="addressPage(1)"
                                                  size="small"
                                                  class="float-right"
                                                  variant="light">
                                            Next
                                        </b-button>
                                        <b-button v-if="showPrevious"
                                                  :disabled="loading"
                                                  @click="addressPage(-1)"
                                                  size="small"
                                                  variant="light">
                                            Previous
                                        </b-button>
                                    </div>
                                </b-overlay>
                            </div>
                            <div v-if="v$.address.$dirty && v$.address.$errors.length" class="error-msg">
                                Please enter the service address
                            </div>
                            <div v-else-if="serviceability_status" class="error-msg" v-html="serviceabilityStatusName"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="services">
                    <b-col class="mb-2">
                        <b-form-group label="Service Type"
                                      class="text-center mb-0">
                            <div v-for="item in services" :key="item.value"
                                 :class="'service-button'+((item.value===service)?' active':'')"
                                 @click="serviceSelect(item.value)">
                                <b-form-group label="Fiberspark 100<br/>Line 2"
                                              :label-for="'input-service-'+item.value">
                                    <template v-slot:label>
                                        <div v-html="item.html"/>
                                    </template>
                                    <b-form-radio v-model="service" :value="item.value" class="text-center"
                                                  :id="'input-service-'+item.value"/>
                                </b-form-group>
                            </div>
                        </b-form-group>
                        <div v-if="v$.service.$dirty && v$.service.$errors.length" class="error-msg">
                            Please choose your service
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Service Start Date"
                                      label-for="input-start_date"
                                      label-class="font-weight-bold"
                                      class="form__start-date">
                            <b-form-datepicker v-model="start_date"
                                               id="input-start_date"
                                               class="mb-2"
                                               label-no-date-selected="Click to select a date"
                                               :min="minDate"
                                               @input="hideAddresses"/>
                            <div v-if="v$.start_date.$dirty && v$.start_date.$errors.length" class="error-msg">
                                Please enter a desired activation date
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="form__agreement">
                        <b-form-group label="Fiberspark Service Agreement:"
                                      label-for="input-accept_terms"
                                      label-class="font-weight-bold">
                            <b-checkbox v-model="accept_terms"
                                        id="input-accept_terms"
                                        @change="hideAddresses">
                                I have read and accept Fiberspark’s
                                <a href="https://www.fiberspark.com/terms.php" target="fiberspark_terms">Terms of
                                    Service and Privacy
                                    Policy</a>.
                            </b-checkbox>
                            <div v-if="v$.accept_terms.$dirty&& v$.accept_terms.$errors.length" class="error-msg">
                                Please accept the terms of service and privacy policy.
                                <br/>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="form-submit">
                    <b-col class="text-center">
                        <b-button type="submit"
                                  class="px-5"
                                  :disabled="submitted"
                                  :variant="submitted ? 'secondary' : 'primary'">
                            Continue
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>

        </b-form>
    </div>
</template>

<script>

import Api from '@/providers/Api'
import * as _ from 'lodash'
import {required, email, minLength} from '@vuelidate/validators'
import Utils from '@/providers/Utils'
import {useVuelidate} from "@vuelidate/core"
import {DateTime} from 'luxon'

function valueIsTrue(value) {
    return value
}

function valueIsTodayOrLater(value) {
    if (!value || (value.length !== 10))
        return false
    const today = DateTime.now().toSQLDate()
    return value >= today
}

function valueIsValidPhone() {
    return this.valid_phone
}

export default {
    name: 'SignupView',
    components: {},
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            address: '',
            start_date: null,
            accept_terms: false,
            addresses: null,
            addresses_cached: null,
            service: null,
            services: [
                {
                    title: 'Fiberspark 100',
                    html: '<strong>Fiberspark 100</strong><br/>Download: 100 Mbps<br/>Upload: 100 Mbps<br/>Price: $55/mo',
                    value: '1'
                },
                {
                    title: 'Fiberspark 500',
                    html: '<strong>Fiberspark 500</strong><br/>Download: 500 Mbps<br/>Upload: 500 Mbps<br/>Price: $65/mo',
                    value: '2'
                },
                {
                    title: 'Fiberspark 1000',
                    html: '<strong>Fiberspark GIGABIT</strong><br/>Download: 1000 Mbps<br/>Upload: 1000 Mbps<br/>Price: $80/mo',
                    value: '3',
                    disabled: false
                },
            ],
            page: 1,
            address_id: null,
            serviceability_status: null,
            network_site: null,
            address_account: null,
            valid_phone: false,
            show: true,
            watching: true,
            loading: false,
            submitted: false,
            statuses: {},
            network_sites: {},
        }
    },
    validations() {
        return {
            name: {required, minLength: minLength(3), $autoDirty: true, $lazy: true},
            email: {required, email, $lazy: true},
            phone: {required, valueIsValidPhone, $lazy: true},
            address: {required, $lazy: true},
            service: {required},
            start_date: {required, valueIsTodayOrLater, $autoDirty: true, $lazy: true},
            accept_terms: {valueIsTrue, $lazy: true}
        }
    },
    computed: {
        minDate: function () {
            return DateTime.now().toSQLDate()
        },
        showAddresses: function () {
            return this.loading || this.addresses && this.addresses.entities && this.addresses.entities.length
        },
        showPrevious: function () {
            return this.addresses && this.addresses.entities &&
                this.addresses.page_info && (this.addresses.page_info.page !== undefined) &&
                (this.addresses.page_info.page > 1)
        },
        showNext: function () {
            return this.addresses && this.addresses.entities &&
                this.addresses.page_info && (this.addresses.page_info.page !== undefined) &&
                (this.addresses.page_info.page < this.addresses.page_info.total_pages)
        },
        items: function () {
            if (this.addresses && this.addresses.entities) {
                return _.map(this.addresses.entities, entity => {
                    let network_sites = [];
                    if (entity.network_site_serviceable_address_lists?.entities) {
                        if (entity.network_site_serviceable_address_lists.entities.length > 0) {
                            _.each(entity.network_site_serviceable_address_lists.entities, network_site => {
                                if (this.network_sites[network_site.id])
                                  network_sites.push(this.network_sites[network_site.id])
                            })
                        }
                    }
                    return {
                        id: entity.id,
                        name: Utils.formatAddress(entity),
                        serviceability_status: entity.address_status?.name,
                        network_site: network_sites.join('; ')
                    }
                })
            } else
                return []
        },
        serviceabilityStatusName: function () {
            return this.serviceability_status ? this.statuses[this.serviceability_status] : ''
        }
    },
    watch: {
        address: function (newVal, oldVal) {
            // changes only
            if (newVal !== oldVal) {
                // check watching flag and value
                if (this.watching) {
                    // clear cached item and page
                    this.address_id = null
                    this.serviceability_status = null
                    this.network_site = null
                    this.page = 1
                    // retrieve addresses
                    this.addressLookup()
                }
            }
        },
    },
    methods: {
        async onSubmit(event) {
            event.preventDefault()
            // hide address list
            this.hideAddresses()
            // touch everything
            this.v$.name.$dirty = true
            this.v$.email.$dirty = true
            this.v$.phone.$dirty = true
            this.v$.address.$dirty = true
            this.v$.service.$dirty = true
            this.v$.start_date.$dirty = true
            this.v$.accept_terms.$dirty = true
            // vuelidate
            const isFormCorrect = await this.v$.$validate()
            if (isFormCorrect) {
                // check for address in use
                const address_id = this.address_account ? null : this.address_id
                // create note
                const display_date = DateTime.fromSQL(this.start_date).toLocaleString(DateTime.DATE_HUGE)
                const service_name = _.find(this.services, ['value', this.service])
                let note = `Requested service is ${service_name ? service_name.title : this.service} with a start date of ${display_date}.`
                if (this.address_account)
                    note += ' There is already service at this address (account ' + this.address_account + ').'
                // persist important values
                this.$store.commit('set_start_date', this.start_date)
                this.$store.commit('set_address_id', address_id)
                this.$store.commit('set_serviceability_status', this.serviceability_status)
                this.$store.commit('set_network_site', this.network_site)
                // actually submit form
                let account_id = null
                let status = null
                this.submitted = true
                Api.signup(this.name, this.email, this.phone, this.address, address_id, note)
                    .then(response => {
                        if (response) {
                            if (response.error)
                                throw new Error(response.error)
                            else {
                                // retrieve account number
                                if (response.data?.createAccount?.id) {
                                    account_id = response.data.createAccount.id
                                    // update internal status
                                    const today = DateTime.now().toSQLDate()
                                    if (!this.address_id)
                                        status = 'NOSERVICE'
                                    else if (this.address_account)
                                        status = 'INUSE'
                                    else if (this.start_date > today)
                                        status = 'SCHEDULE'
                                    else
                                        status = 'ACTIVATE'
                                    // persist account identifier and status
                                    this.$store.commit('set_account_id', account_id)
                                    this.$store.commit('set_status', status)
                                    // add service to account
                                    return Api.service(account_id, this.service)
                                } else
                                    this.$toasted.error('Unable to retrieve account identifier')
                            }
                        }
                    })
                    .then(response => {
                        if (response) {
                            if (response.error)
                                throw new Error(response.error)
                            else {
                                // craft notification message
                                const message = 'A new Sonar account has been created:\n\n' +
                                    'Name: ' + this.name + '\n' +
                                    'Email: ' + this.email + '\n' +
                                    'Phone: ' + this.phone + '\n' +
                                    'Address: ' + this.address + '\n' +
                                    'Service: ' + (service_name ? service_name.title : this.service) + '\n' +
                                    'Serviceability Status: ' + this.serviceability_status + '\n' +
                                    'Network Site: ' + this.network_site + '\n' +
                                    'Start date: ' + display_date + '\n' +
                                    'Status: ' + status + '\n' +
                                    '\n' +
                                    'Sonar URL: ' + 'https://fiberspark.sonar.software/app#/accounts/show/' + account_id
                                // send notification message; no exceptions
                                return Api.sendmail('Web account created', message, 'SUPPORT')
                            }
                        }
                    })
                    .then(() => {
                        // check for status=ReadyForDrop and site=Sciencenter
                        if ((this.serviceability_status === 'Ready for Drop') && ((this.network_site ?? '').includes('Sciencenter') >= 0)) {
                            console.log('Signup:onSubmit  this.serviceability_status=', this.serviceability_status, '  this.network_site=', this.network_site)
                            // craft installation message
                            const message = 'A new drop request has been created along the Southern Tier Network:\n\n' +
                                'Name: ' + this.name + '\n' +
                                'Email: ' + this.email + '\n' +
                                'Phone: ' + this.phone + '\n' +
                                'Address: ' + this.address + '\n' +
                                'Service: ' + (service_name ? service_name.title : this.service) + '\n' +
                                'Serviceability Status: ' + this.serviceability_status + '\n' +
                                'Network Site: ' + this.network_site + '\n' +
                                'Start date: ' + display_date + '\n' +
                                'Status: ' + status + '\n' +
                                '\n' +
                                'Sonar URL: ' + 'https://fiberspark.sonar.software/app#/accounts/show/' + account_id
                            // send notification message; no exceptions
                            return Api.sendmail('Fiberspark STN Installation', message, 'STN')
                        } else {
                            return new Promise(() => {});
                        }
                    })
                    .then(() => {
                        // forward to payment or completion page
                        if (status !== 'NOSERVICE') {
                            const id_encrypted = Utils.encrypt(account_id)
                            this.$router.push({name: 'payment', params: {id: id_encrypted}})
                        } else
                            // skip payment and show completion page
                            this.$router.push({name: 'done'})
                    })
                    .catch(error => {
                        const message = (error && error.message) ? error.message : (error ? error : 'An error occurred')
                        this.$toasted.error(message)
                    })
                    .finally(() => {
                        this.submitted = false
                    })
            } else
                this.$toasted.error('Please check the form errors and resubmit your form')
        },
        onReset(event) {
            event.preventDefault()
            // reset our form values
            this.name = ''
            this.email = ''
            this.phone = ''
            this.address_id = null
            this.serviceability_status = null
            this.network_site = null
            this.address_account = null
            this.address = null
            // trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        },
        validatePhone(phone) {
            if (phone)
                this.valid_phone = phone.valid
        },
        hideAddresses() {
            this.addresses = null
        },
        focusAddresses() {
            if (this.address && this.addresses_cached)
                this.addresses = this.addresses_cached
        },
        addressLookup() {
            const term = this.address
            if (term) {
                this.loading = true
                Api.addressLookup(term, this.page)
                    .then(response => {
                        if (response) {
                            if (response.error) {
                                this.addresses = this.addresses_cached = null
                                throw new Error(response.error)
                            } else if (response.data && response.data.addresses) {
                                this.addresses = response.data.addresses
                                this.addresses_cached = _.cloneDeep(this.addresses)
                            } else
                                this.addresses = this.addresses_cached = null
                        }
                    })
                    .catch(error => {
                        const message = (error && error.message) ? error.message : (error ? error : 'An error occurred')
                        this.$toasted.error(message)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else
                this.addresses = this.addresses_cached = null
        },
        addressPage(delta) {
            this.page += delta
            this.addressLookup()
        },
        async addressSelect(id, name, serviceability_status, network_site) {
            if (!this.loading) {
                // clear watching flag
                this.watching = false
                // set identifier and update address
                this.address_id = id
                this.serviceability_status = serviceability_status
                this.network_site = network_site
                this.address = name
                // clear displayed address list (not cached list)
                this.addresses = null
                // check if already serviced
                this.address_account = null
                Api.accountAddress(this.address_id)
                    .then(response => {
                        if (response && response.data && response.data.accounts && response.data.accounts.entities && Array.isArray(response.data.accounts.entities) && response.data.accounts.entities.length)
                            this.address_account = response.data.accounts.entities[0].id
                    })
                    .catch(error => {
                        const message = (error && error.message) ? error.message : (error ? error : 'An error occurred')
                        this.$toasted.error(message)
                    })
                // set watching flag (next cycle)
                this.$nextTick(() => {
                    this.watching = true
                })
            }
        },
        // addressDetails() {
        //   this.$bvModal.show('address-details')
        // },
        serviceSelect(service) {
            this.service = service
        },
    },
    mounted() {
        // retrieve statuses & network sites
        Api.statuses()
            .then(response => {
                if (response?.data?.canned_replies?.entities) {
                    _.each(response.data.canned_replies.entities, status => {
                        this.statuses[status.name] = status.body
                    })
                }
        })
        .catch(error => {
            const message = (error && error.message) ? error.message : (error ? error : 'An error occurred')
            this.$toasted.error(message)
        })
        Api.network_sites()
            .then(response => {
                if (response?.data?.network_sites?.entities) {
                    _.each(response.data.network_sites.entities, network_site => {
                        this.network_sites[network_site.id] = network_site.name
                    })
                }
            })
            .catch(error => {
                const message = (error && error.message) ? error.message : (error ? error : 'An error occurred')
                this.$toasted.error(message)
            })
   }
}
</script>

<style lang="scss">
@use "sass:color";

$red: #C3292F;
$blue: #007bff;

#signup {
    max-width: 800px;
    margin: 0 auto;

    .items {
        margin: 0 0 0 20px;
        padding: 5px 0 0 0;
        position: absolute;
        background: #FFFDD0;
        opacity: 0.95;
        border: 1px solid lightgray;
        border-radius: 10px;
        z-index: 999;

        div {
            min-width: 260px;
            min-height: 80px;
            max-height: 350px;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            overflow-y: auto;
        }

        .item {
            list-style-type: none;
            margin: 0;
            padding: 4px 20px 4px 20px;
            border-bottom: 1px solid lightgray;
            cursor: default;
        }
    }

    legend {
        font-family: "century gothic", sans-serif;
        font-size: 21px;
        font-weight: normal;
    }
}

.service-button {
    background-color: #e8e8e8;
    //border: 1px solid #ccc;
    border-radius: 12px;
    padding: 20px;
    margin-right: 12px;
    margin-bottom: 8px;
    white-space: nowrap;
    transition: all .3s;
    cursor: pointer;

    &.active {
        background-color: $blue;
        color: #fff;

        .custom-control-label::before {
            border-color: $red !important;
            background-color: $red !important;
        }
    }

    label {
        cursor: pointer;
    }

    &:hover:not(.active) {
        cursor: pointer;
        background-color: $red;
        border-color: $red;
        color: #fff;
    }

    .custom-control-input:checked {
        background-color: color.adjust($red, $lightness: 10%);
        border-color: color.adjust($red, $lightness: 10%);
    }

    div > strong {
        font-size: 18px;
    }
}

.vue-tel-input {
    border-color: #ced4da !important;

    .tel {
        border-color: #ced4da;
        height: 44px;
        @media (min-width: 768px) {
            height: 60px;
        }
    }
}

.services {
    padding: 2em 0;
    @media (max-width: 767px) {
        > div {
            max-width: 260px;
            margin: 0 auto;
        }
    }

    fieldset > div {
        @media (min-width: 768px) {
            display: flex;
            justify-content: space-between;
            max-width: 600px;
            margin: 0 auto;
        }
    }
}

.form__start-date, .form__agreement {
    margin: 0 auto;
}

.form__start-date {
    max-width: 280px !important;
    padding-bottom: 2em;
}

.form__agreement {
    max-width: 600px !important;
    //padding-bottom: 1em;
}

</style>
