import Vue from 'vue'
import Vuex from 'vuex'
// import VueResource from 'vue-resource'
import * as _ from 'lodash'
import VuexPersistence from 'vuex-persist'

// Vue.use(VueResource)
Vue.use(Vuex)

// https://vuex.vuejs.org/guide/mutations.html

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export const store = new Vuex.Store({
    state: {
        // account identifier
        account_id: null,
        // address identifier
        address_id: null,
        // serviceability status name
        serviceability_status: null,
        // network site name
        network_site: null,
        // start date
        start_date: null,
        // status
        status: null,
    },
    actions: {
        submit (context, payload) {
            context.commit(payload.mutation, payload.payload)
            localStorage.setItem('vuex', JSON.stringify(context.state))
        },
        initStore (context) {
            if (localStorage.getItem('vuex')) {
                const savedState = JSON.parse(localStorage.getItem('vuex'))
                _.each(savedState, (value, key) => {
                    Vue.set(context.state, key, value)
                })
            }
        },
    },
    mutations: {
        initStore (state) {
            if (localStorage.getItem('vuex')) {
                const savedState = JSON.parse(localStorage.getItem('vuex'))
                _.each(savedState, (value, key) => {
                    Vue.set(state, key, value)
                })
            }
        },
        set_account_id (state, account_id) {
            Vue.set(state, 'account_id', account_id)
        },
        set_address_id (state, address_id) {
            Vue.set(state, 'address_id', address_id)
        },
        set_serviceability_status (state, serviceability_status) {
            Vue.set(state, 'serviceability_status', serviceability_status)
        },
        set_network_site (state, network_site) {
            Vue.set(state, 'network_site', network_site)
        },
        set_start_date (state, start_date) {
            Vue.set(state, 'start_date', start_date)
        },
        set_status (state, status) {
            Vue.set(state, 'status', status)
        },
    },
    plugins: [vuexLocal.plugin]
})
